<template>
  <div class="page">
    <el-dialog
      :title="$t('vipCoupon.vipCoupon')"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
    <div class="page_box">
      <!-- <div class="vip_level_nav">VIP优惠券</div> -->
      <div class="vip_level_dec">
        {{$t('vipCoupon.dec1')}}
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">{{$t('vipCoupon.table1')}}</div>
          <div class="vip_level_tb">{{$t('vipCoupon.table2')}}</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">1</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText2')}}*1</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">2</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText2')}}*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">3</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText2')}}*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">4</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText3')}}*1</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">5</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText3')}}*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">6</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText3')}}*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">7</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText4')}}*1</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">8</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText4')}}*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">9</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText4')}}*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">10</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText5')}}*1</div>
        </div>
         <div class="vip_level_tr">
          <div class="vip_level_tb">11</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText5')}}*2</div>
        </div>
         <div class="vip_level_tr">
          <div class="vip_level_tb">12</div>
          <div class="vip_level_tb">{{$t('vipCoupon.tableText5')}}*3</div>
        </div>
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">{{$t('vipCoupon.dec2')}}</span> {{$t('vipCoupon.dec2Text')}}
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">{{$t('vipCoupon.dec3')}}</span> {{$t('vipCoupon.dec3Text')}}
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">{{$t('vipCoupon.dec4')}}</span> {{$t('vipCoupon.dec4Text')}}
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">{{$t('vipCoupon.dec5')}}</span>{{$t('vipCoupon.dec5Text')}}
      </div>
    </div>
    </el-dialog>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible:false,
    };
  },
  methods: {
    handleClose() {
      this.$parent.dialogFormVisible = true;
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #409EFF;
div{
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.vip_level_nav {
  padding: 5px 65px;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
  width: 100px;
  font-size: 18px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_coupon{
  font-weight: bold;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: .0625rem solid #ddd;
  border-bottom: .0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: .0625rem solid #ddd;
  border-right: .0625rem solid #ddd;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
}
</style>