<template>
  <div class="">
    <el-dialog
      title="Unlock"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page-content">
        <el-radio-group v-model="payCountry" @change="handleChangeCountry">
          <el-radio
            :label="item.value"
            border
            v-for="item in country"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <!-- <div class="page-content">
        <div class="pay-input-bg">
          <div class="pay-input-name">{{ chargeInfo.unit }}：</div>
          <div id="pay_input" class="pay_input">
            {{ chargeInfo.charge_coin }}
          </div>
        </div>
      </div> -->
      <div class="page-content">
        <el-radio-group v-model="radio">
          <el-radio
            :label="item.pay_type"
            border
            v-for="item in chargeInfo.atm"
            :key="item.pay_type"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="pay-value">{{ chargeInfo.unit }}：<span style="color: red;">{{ chargeInfo.charge_coin }}</span></div>
        <el-button type="primary" @click="confirm">{{
          $t("unlock.confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      radio: "",
      chargeInfo: {},
      payCountry:'VN',
      country: [
        {
          value: "VN",
          label: "Việt Nam",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "PH",
          label: "Philippines",
        },
        {
          value: "TH",
          label: "ประเทศไทย",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      {
        if (!this.radio) {
          this.$message.warning(this.$t("goodsPay.chooseProduce"));
          return;
        }
        const { charge_id, charge_coin } = this.chargeInfo;
        let params = {
          pay_type: this.radio,
          pay_amt: charge_coin,
          charge_id: charge_id,
        };
        this.$http.post(`/pay/chargeCoin?country=${this.payCountry}`, params).then((res) => {
          if (res.data.code === 200) {
            window.location.href = res.data.data.redirect_url;
          } else {
            this.$message.error(res.data.msg);
          }
        });
        console.log(this.radio);
        this.dialogFormVisible = false;
      }
    }, 300),
    getData(e) {
      let params = {};
      this.$http.get(`/pay/chargeDetail?country=${e}`, { params }).then((res) => {
        if (res.data.code === 200) {
          this.chargeInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.radio = "";
      this.dialogFormVisible = false;
    },
    handleChangeCountry(e){
      this.getData(e) 
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
  margin-top: 10px;
}
.dialog-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .pay-value{
    margin-right: 10px;
  }
}
.page-content {
  margin: 8px 0;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 1.6px;
  box-shadow: 0 0 5px #eee;
  .pay-input-bg {
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    width: 100%;
    box-sizing: border-box;
    .pay_input {
      flex: 1;
      display: block;
      padding: 0 10px;
      font-size: 24px;
      color: #ff2b11;
      line-height: 12.8px;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  /deep/.el-radio.is-bordered {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
