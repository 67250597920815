<template>
  <div class="">
    <el-dialog
      title="Coin"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :width="$isMobile ? '90%' : '400px'"
    >
      <div class="content">
        <div>
          <span>{{$t('coin.walletBalance')}}</span>
          <span>
            <span class="num_color">{{ Number(coinInfo.coin) }}</span>
            Coin
          </span>
        </div>
        <div>
          <span>{{$t('coin.coinBalance')}}</span>
          <span>
            <span class="num_color">{{ Number(coinInfo.bind_coin) }}</span>
            Coin
          </span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> {{$t('unlock.confirm')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      coinInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },
    
    handleClose() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
.content {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    span {
      flex: 1;
      text-align: left;
      .num_color {
        color: red;
        font-weight: bold;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
}
</style>
