<template>
  <div class="page">
    <el-dialog
      :title="$t('vipGift.vipGift')"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <!-- <div class="vip_level_nav">VIP礼包</div> -->
        <div class="vip_level_dec">
          {{$t('vipGift.dec1')}}
        </div>
        <div class="vip_level_dec">
         {{$t('vipGift.dec2')}}
        </div>
        <div class="vip_level_dec">
          {{$t('vipGift.dec3')}}
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">{{$t('vipGift.table1')}}</div>
            <div class="vip_level_tb">{{$t('vipGift.table2')}}</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">1~3</div>
            <div class="vip_level_tb">{{$t('vipGift.tableText')}} I</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">4~6</div>
            <div class="vip_level_tb">{{$t('vipGift.tableText')}} II</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">7~9</div>
            <div class="vip_level_tb">{{$t('vipGift.tableText')}} III</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">10~11</div>
            <div class="vip_level_tb">{{$t('vipGift.tableText')}} IV</div>
          </div>
           <div class="vip_level_tr">
            <div class="vip_level_tb">12</div>
            <div class="vip_level_tb">{{$t('vipGift.tableText')}} V</div>
          </div>
        </div>
        <div class="vip_selects">
          <el-form ref="ruleForm" label-width="100px" class="ruleForm">
            <el-form-item label="">
              <el-select
                style="width: 100%"
                :popper-append-to-body="true"
                :no-data-text="$t('consume.noData')"
                :no-match-text="$t('consume.noData')"
                v-model="form.servers"
                @change="handleChange"
                filterable
                :placeholder="$t('vipGift.chooseServer')"
              >
                <el-option
                  :label="item.value"
                  :value="item.id"
                  v-for="item in servers_roles.servers"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                style="width: 100%"
                v-model="form.roles"
                :no-data-text="$t('consume.noData')"
                :no-match-text="$t('consume.noData')"
                :disabled="!form.servers"
                filterable
                :placeholder="$t('vipGift.chooseServer')"
              >
                <el-option
                  :label="item.value"
                  :value="item.id"
                  v-for="item in servers_roles.roles"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="get_gift">
          <div class="submit_btn" @click="getGift()">{{$t('vipGift.receivingGifts')}}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('vipGift.gift')"
      center
      class="vipConfirm"
      :visible.sync="dialogFormConfirmVisible"
    >
      <div class="confirm_content">
        <img class="pop_gift_icon" src="@/assets/img/vip/gift_icon.png" />
        <div class="pop_content">
          <div class="pop_info">
            <!-- <div>
              {{$t('vipGift.confirmUse')}} <span>{{ giftVal }} Điểm</span>
            </div> -->
            <div>{{$t('vipGift.usePrivileges')}}</div>
            <div class="pop_serve">Server : {{ userInfo.serversName }}</div>
            <div>{{$t('consume.roleName')}} {{ userInfo.roleName }}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleConfirmClose"> {{$t('Info.cancel')}} </el-button>
        <el-button type="primary" @click="confirm()"> {{$t('Login.confirm')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      dialogFormConfirmVisible: false,
      giftVal: 0, //获取到的礼包值
      servers_roles: {
        servers: [],
        roles: [],
      }, //区服角色
      form: {
        servers: "",
        roles: "",
      },
      userInfo: {
        level: 0,
        serversName: "",
        roleName: "",
      },
    };
  },
  computed: {
    filteredSubcategories() {
      return this.subcategories[this.selectedCategory] || [];
    },
  },
  methods: {
    getGift() {
      let { roles, servers } = this.form;
      if (!roles || !servers) {
        this.$message.error(this.$t('vipGift.chooseServerAndRole'));
        return;
      }

      // 特权礼包1 等级 1 ~ 3
      if (this.userInfo.level >= 1 && this.userInfo.level <= 3) {
        this.giftVal = 50;
      }
      // 特权礼包2 等级 4 ~ 6
      if (this.userInfo.level >= 4 && this.userInfo.level <= 6) {
        this.giftVal = 100;
      }
      // 特权礼包3 等级 7 ~ 9
      if (this.userInfo.level >= 7 && this.userInfo.level <= 9) {
        this.giftVal = 200;
      }
      // 特权礼包4 等级 10
      if (this.userInfo.level == 10) {
        this.giftVal = 500;
      }
      // 获取到当前选择的区服角色名
      let role = this.servers_roles.roles.filter((item) => {
        return item.id === this.form.roles;
      });
      let server = this.servers_roles.servers.filter((item) => {
        return item.id === this.form.servers;
      });
      this.userInfo.roleName = role[0].value;
      this.userInfo.serversName = server[0].value;
      // let userInfo = [server, role]
      this.dialogFormConfirmVisible = true;
    },
    //选择区服时
    handleChange(e) {
      this.form.roles = "";
      let roles = this.servers_roles.servers.filter((item) => {
        return item.id === e;
      });
      this.servers_roles.roles = roles[0].childs;
    },
    handleClose() {
      (this.form = {
        servers: "",
        roles: "",
      }),
        (this.$parent.dialogFormVisible = true);
      this.dialogFormVisible = false;
    },
    confirm() {
      let { roles, servers } = this.form;
      let params = {
        role_id: roles,
        server_id: servers,
      };
      this.$http.post("/center/exchangeGift", params).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleConfirmClose() {
      this.dialogFormConfirmVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #409eff;
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
div {
  white-space: pre-wrap;
  word-break: break-word;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.vip_level_nav {
  margin: 6.4px 0 1.6px 0;
  padding: 0 32px;
  line-height: 32px;
  font-size: 16px;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 16px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  width: 100%;
  line-height: 32px;
  font-size: 16px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
.vip_selects {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 24px 0;
  select {
    height: 40px;
    width: 200px;
    overflow-y: scroll;
    border: 0.0625rem solid #e1e1e1;
  }
  select:focus {
    outline: none;
  }
}
.get_gift {
  width: 100%;
  display: flex;
  justify-content: center;
  .submit_btn {
    margin-top: 8px;
    width: 50%;
    line-height: 32px;
    text-align: center;
    background-color: $vip-color;
    color: #fff;
    border-radius: 16px;
    cursor: pointer;
  }
}
.ruleForm {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.pop_gift_icon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.pop_content{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.pop_info {
  font-size: 15px;
  position: relative;
  left: 0vw;
}
.pop_serve {
  margin-top: 10px;
}
.pop_info span {
  color: #00a7d0;
}
.vipConfirm /deep/.el-dialog {
    width: 500px;
  }

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
  .vipConfirm /deep/.el-dialog {
    width: 95%;
  }
  .ruleForm {
    width: 95%;
    flex-direction: column;
  }
}
</style>