<template>
  <div
    class="cs-btn"
    :style="{ bottom: showAdvert ? '144px' : '22px' }"
    v-show="show"
  >
    <img alt="" @click="handleClick" class="outside" src="@/assets/outside.png" />
    <img alt="" @click="handleClick" class="inside" src="@/assets/inside.png" />
    <i
      id="HelpCenter_Close_I"
      class="el-icon-error cs-btn-close"
      @click="show = false"
    ></i>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    showAdvert: Boolean,
    url: String,
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClick(){
      // console.log(this.url);
      window.open(this.url, '_blank')
    }
  },
};
</script>

<style scoped lang="scss">
.cs-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  -webkit-transform: all 1s;
  transform: all 1s;
  cursor: pointer;
  z-index: 99;
  -webkit-animation: fadeToLeft 1s ease-in-out;
  animation: fadeToLeft 1s ease-in-out;
  .outside {
    -webkit-filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5));
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: block;
  }

  .inside {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -55%);
    transform: translate(-50%, -55%);
    z-index: 2;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
}

.cs-btn:hover .inside {
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translate(-50%, -55%) rotate(-20deg) scale(1.2);
  transform: translate(-50%, -55%) rotate(-20deg) scale(1.2);
}

.cs-btn-close {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  font-size: 26px;
  color: #d3dce6;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

@-webkit-keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@media screen and (max-width: 1000px) {
  .cs-btn .outside {
    width: 50px;
    height: auto;
  }
  .cs-btn .inside {
    width: 27.5px;
    height: auto;
  }
}
</style>
