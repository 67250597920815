<template>
  <div class="">
    <el-dialog
      :title="$t('codeBind.beta')"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" ref="form">
        <el-form-item :label="$t('codeBind.code')+':'" :label-width="formLabelWidth">
          <el-input
            v-model.trim="form.code"
            maxlength="30"
            :on-change="
              (form.code = form.code.replace(/[^\w_]/g, ''))
            "
            :placeholder="$t('codeBind.enterCode')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="beta_notice" v-html="CodeBindInfo.beta_notice"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">{{ $t('codeBind.activation')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      form: {
        code: "",
      },
      CodeBindInfo:{},
      formLabelWidth: "100px",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      if (!this.form.code) {
        this.$message.warning(this.$t('codeBind.enterCode'));
        return;
      }
      let params = this.form;
      this.$http.post("/reg/activate", params).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          // this.form = {
          //   code:''
          // };
          this.$parent.getGateData();
          // this.dialogFormVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }, 300),
    handleClose() {
      this.form = {
        code: "",
      };
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}

/deep/.el-dialog__body{
  padding: 15px 20px;
}
.beta_notice{
  max-height: 300px;
  overflow: auto;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .beta_notice{
  max-height: 200px;
  overflow: auto;
}
}
</style>
