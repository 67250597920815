<template>
  <div class="">
    <el-dialog
      :title="$t('goodsPay.game')"
      :width="info.type == '1' ? '' : '90%'"
      :visible.sync="dialogFormVisible"
      center
      :before-close="handleClose"
    >
      <el-form :model="form" ref="form" v-if="info.type == '1'">
        <el-form-item :label="$t('goodsPay.receiver')" :label-width="formLabelWidth">
          <el-input
            v-model="form.receive_name"
            :placeholder="$t('goodsPay.enterReceiver')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('goodsPay.phone')" :label-width="formLabelWidth">
          <el-input v-model="form.mobile" :placeholder="$t('goodsPay.enterPhone')">
            <template slot="prepend">84-</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('goodsPay.address')" :label-width="formLabelWidth">
          <el-input
            v-model="form.address"
            :placeholder="$t('goodsPay.enterAddress')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('goodsPay.payType')"
          v-if="info.pay_type === '2'"
          :label-width="'200px'"
        >
          <el-radio-group v-model="form.pay_type">
            <el-radio
              :label="item.pay_type"
              border
              v-for="item in info.atmList"
              :key="item.pay_type"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-else>
        <div style="text-align: center">{{$t('goodsPay.confirmProduct')}}</div>
        <div>
          <el-form :model="form" ref="form" v-if="info.pay_type == '2'">
            <el-form-item
              :label="$t('goodsPay.payType')"
              :label-width="'200px'"
            >
              <el-radio-group v-model="form.pay_type">
                <el-radio
                  :label="item.pay_type"
                  border
                  v-for="item in info.atmList"
                  :key="item.pay_type"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> {{$t('goodsPay.cancel')}} </el-button>
        <el-button type="primary" @click="confirm"> {{$t('goodsPay.confirm')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      info: {},
      dialogFormVisible: false,
      form: {
        receive_name: "",
        mobile: "",
        address: "",
        pay_type: "",
      },
      formLabelWidth: "100px",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      this.PurchaseGoods();
    }, 300),
    //购买商品
    PurchaseGoods() {
      console.log(this.info);
      let params = {
        goods_id: this.info.id,
        ...this.form,
      };
      // if (this.info.amount || Number(this.info.amount) > 0) {
      //   // 当时商品还要金额购买，需要传支付方式
      //   params.pay_type = atm[payTypeCheckIndex].pay_type;
      // }
      if (Number(this.info.amount) === 0) {
        params.pay_type = this.info.pay_type;
      }
      const { receive_name, mobile, address, pay_type } = params;
      if (this.info.is_virtual == "0") {
        // 实物商品才传收获地址
        params.receive_name = this.form.receive_name;
        params.mobile = "84-" + this.form.mobile;
        params.address = this.form.address;
        if (!receive_name || !mobile || !address || !pay_type) {
          this.$message.warning(this.$t('goodsPay.enterCompleteInfo'));
          return;
        }
      }
      if (this.info.pay_type === "2" && !pay_type) {
        this.$message.warning(this.$t('goodsPay.chooseProduce'));
        return;
      }
      this.$http.post("/point/exchange", params).then((res) => {
        if (res.data.code === 200) {
          this.form = {
            receive_name: "",
            mobile: "",
            address: "",
            pay_type: "",
          };
          if (res.data.data.gift_code) {
            this.$message.success(res.data.data.gift_code);
          } else if (res.data.data.redirect_url) {
            window.location.href = res.data.data.redirect_url;
          } else {
            this.$message.success(res.data.msg);
          }

          this.dialogFormVisible = false;
          this.$parent.dialogFormVisible = false;
          this.$parent.$parent.getSelectData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.form = {
        receive_name: "",
        mobile: "",
        address: "",
      };
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  width: auto !important;
  border-radius: 32px !important;
}
.el-radio {
  width: 150px;
  margin-bottom: 10px;
  margin-left: -10px !important;
}
// .el-radio.is-bordered + .el-radio.is-bordered {

// }
@media screen and (max-width: 1024px) {
  /deep/.el-dialog {
    width: 90% !important;
  }
  .el-form {
    height: auto;
    max-height: 300px;
    overflow: auto;
  }
  .el-radio-group {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
  }
  /deep/.el-radio {
    margin-right: 10px;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 376px) {
  /deep/.el-dialog {
    width: 90% !important;
    border-radius: 32px !important;
  }
  // .el-radio-group {
  //   margin-left: 20%;
  // }
  .el-form {
    height: 260px;
    overflow: auto;
  }
}
@media screen and (min-width: 281px) and (max-width: 376px) {
  // .el-radio-group {
  //   margin-left: 25%;
  // }
}
</style>
