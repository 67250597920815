<template>
  <div class="">
    <el-dialog
      :title="$t('goodsDetail.gift')"
      v-if="GoodsDetailDialog"
      :width="$isMobile ? '90%' : '30%'"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      center
    >
      <div class="center">
        <div class="shop_header button_primary"></div>
        <div class="shop_main">
          <img :src="info.img" />
          <div class="shop_name">{{ info.name }}</div>
          <div class="shop_content" v-html="info.content"></div>
          <div class="shop_cell">
            {{ $t("goodsDetail.sellingPrice") }} :
            <span>{{ info.point }} {{ $t("store.integral") }} </span>
            <span v-if="Number(info.amount)"> + {{ info.amount }}VND</span>
          </div>
          <div class="shop_cell">
            {{ $t("goodsDetail.RemainingNum") }} :
            <span>{{ info.used }}/{{ info.total }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div style="margin-bottom: 12px" v-if="!info.show">{{ info.btn }}</div>
        <el-button @click="handleClose"> {{ $t("goodsPay.cancel") }} </el-button>
        <el-button
          v-if="info.show"
          :type="info.show ? 'primary' : 'info'"
          @click="confirm()"
        >
          {{ info.btn }}
        </el-button>
      </div>
    </el-dialog>
    <GoodsPay ref="goodsPay" />
  </div>
</template>

<script>
import GoodsPay from "./goodsPay.vue";
export default {
  name: "",
  mixins: [],
  components: { GoodsPay },
  props: {
    info: Object,
  },
  data() {
    return {
      dialogFormVisible: false,
      GoodsDetailDialog: false,
      btn: "",
      show: true,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      if (!this.info.show) {
        return;
      }
      this.$refs.goodsPay.dialogFormVisible = true;
      this.$refs.goodsPay.info = this.info;
      // this.dialogFormVisible = false;
    },
    message(val) {
      this.$message.warning(val);
    },
    handleClose() {
      this.dialogFormVisible = false;
      this.GoodsDetailDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
.center {
  .shop_header {
    width: 100%;
    height: 150px;
  }

  .button_primary {
    background-image: linear-gradient(to right, #57ebfe, #1188ff);
    color: #fff;
    box-shadow: 0 0 0.8px #57ebfe;
  }
  .shop_main {
    z-index: 1;
    position: relative;
    margin: -110px auto -5px;
    padding: 3.2px;
    width: 90%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 1.6px #ddd;
    border-radius: 3px;
    img {
      display: block;
      width: 100%;
    }
    .shop_name {
      padding: 3.2px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .shop_content {
      margin: 1.6px 0;
      word-break: keep-all;
      p {
        margin: 0;
      }
    }
    .shop_cell {
      span {
        margin-left: 1.6px;
        font-size: 14px;
      }
    }
  }
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}

@media screen and (max-width: 1000px) {
  .center {
    .shop_main {
      font-size: 12px;
      img {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .shop_main {
    overflow: auto;
    height: 210px;
  }
}
</style>
